<template>
  <a-form class="ant-advanced-search-form" name="advanced_search">
    <div class="filter-and-show">
      <div class="filter-fields">
        <h3>Фильтр</h3>
        <a-form-item label="Производитель" class="filter-form-items">
          <a-select
            placeholder="Производитель"
            class="dealer-select selects"
            default-value="Автоград плюс"
          >
            <a-select-option value="autograd-plus">
              Автоград плюс
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Регион" class="filter-form-items">
          <a-select
            placeholder="Регион"
            class="reg-select selects"
            default-value="all"
          >
            <a-select-option value="all">Все</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Бренд" class="filter-form-items">
          <a-select
            placeholder="Бренд"
            class="brand-select selects"
            default-value="all"
          >
            <a-select-option value="all">Все</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Менеджер" class="filter-form-items">
          <a-input placeholder="Менеджер" class="manager-select selects">
            Павел Павлов
          </a-input>
        </a-form-item>
        <a-form-item label="Период" class="filter-form-items">
          <a-input placeholder="Период" class="period-select selects">
            Ноябрь 2020
          </a-input>
        </a-form-item>
      </div>
      <div class="filter-fields">
        <h3>Показывать</h3>

        <a-form-item label="Линии" class="filter-form-items">
          <a-select
            class="lines-select selects"
            default-value="yes"
            :value="isLineShow ? 'yes' : 'no'"
            @change="value => updateFilterIsLineShow(value)"
          >
            <a-select-option value="yes">Да</a-select-option>
            <a-select-option value="no">Нет</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Суммы" class="filter-form-items">
          <a-select
            class="amounts-select selects"
            default-value="no"
            :value="isSumShow ? 'yes' : 'no'"
            @change="value => updateFilterIsSumShow(value)"
          >
            <a-select-option value="yes">Да</a-select-option>
            <a-select-option value="no">Нет</a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </div>
    <div class="buttons">
      <div>
        <a-button class="leftButtons" @click="filter">Фильтр</a-button>
        <a-button class="leftButtons" @click="clearFilters">Очистить</a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"

export default {
  name: "Filters",
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
    }
  },
  computed: mapGetters({
    isLineShow: "reportPeriod/isTempLineShow",
    isSumShow: "reportPeriod/isTempSumShow",
  }),
  methods: mapMutations({
    updateFilterIsLineShow: "reportPeriod/UPDATE_FILTER_IS_LINE_SHOW",
    updateFilterIsSumShow: "reportPeriod/UPDATE_FILTER_IS_SUM_SHOW",
    filter: "reportPeriod/FILTER",
    clearFilters: "reportPeriod/CLEAR_FILTERS",
  }),
}
</script>

<style lang="scss" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}

#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}

.search-result-text {
  margin: 20px 0 10px 0;
}

.filter-and-show {
  display: flex;
  flex-direction: row;
}

.filter-fields {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.filter-form-items {
  display: block;
  margin-right: 20px;
  margin-bottom: 10px !important;
}

.leftButtons {
  margin-right: 20px;
}
.rightButtons {
  margin-left: 20px;
}

.dealer-select {
  margin-left: 40px;
}
.reg-select {
  margin-left: 36px;
}
.brand-select {
  margin-left: 40px;
}
.manager-select {
  margin-left: 12px;
}
.period-select {
  margin-left: 31px;
}

.selects {
  width: 200px;
}

.lines-select {
  margin-left: 20px;
}
.amounts-select {
  margin-left: 14px;
}
</style>
