<template>
  <div class="reports-period-page">
    <h3>Период</h3>
    <Period />
  </div>
</template>

<script>
import Period from "@/components/reports/Period"

export default {
  name: "ReportsPeriod",
  components: {
    Period,
  },
}
</script>

<style lang="scss" scoped></style>
