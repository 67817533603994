<template>
  <div class="period">
    <Filters />
    <PeriodLine class="element" v-if="isLineShow" />
    <PeriodSum class="element" v-if="isSumShow" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Filters from "@/components/reports/period/Filters"
import PeriodLine from "@/components/reports/period/PeriodLine"
import PeriodSum from "@/components/reports/period/PeriodSum"

export default {
  name: "Period",
  components: {
    Filters,
    PeriodLine,
    PeriodSum,
  },
  computed: mapGetters({
    isLineShow: "reportPeriod/isLineShow",
    isSumShow: "reportPeriod/isSumShow",
  }),
}
</script>

<style lang="scss" scoped>
.period {
  .element {
    margin-bottom: 80px;
  }
}
</style>
