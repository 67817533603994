<template>
  <div class="period-sum">
    <h3>Суммы</h3>

    <div class="hideButtonHolder">
      <a-button
        type="primary"
        class="hideButton"
        @click="toggleFirstTableVisibility"
      >
        {{ isHiddenFirstTable ? "Показать" : "Скрыть" }}
      </a-button>
    </div>

    <a-table
      bordered
      v-if="!isHiddenFirstTable"
      :columns="firstTableColumns"
      :data-source="firstTableData"
      :scroll="{ x: 2500 }"
      :pagination="false"
    ></a-table>

    <div class="hideButtonHolder">
      <a-button
        type="primary"
        class="hideButton"
        @click="toggleSecondTableVisibility"
      >
        {{ isHiddenSecondTable ? "Показать" : "Скрыть" }}
      </a-button>
    </div>

    <a-table
      bordered
      v-if="!isHiddenSecondTable"
      :columns="secondTableColumns"
      :data-source="secondTableData"
      :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
    ></a-table>

    <Diagrams :list="diagramsList" />
  </div>
</template>

<script>
import Diagrams from "@/components/diagrams/Diagrams"

import { mapMutations, mapActions, mapGetters } from "vuex"

export default {
  name: "PeriodSum",
  components: {
    Diagrams,
  },
  data() {
    return {
      diagramsList: [
        {
          link: require("../../../assets/reports/period/sum/diagram1.png"),
        },
        {
          link: require("../../../assets/reports/period/sum/diagram2.png"),
        },
      ],
    }
  },
  mounted() {
    this.fetchFirstTableData()
    this.fetchSecondTableData()
  },
  computed: mapGetters({
    isHiddenFirstTable: "reportPeriod/isHiddenSumFirstTable",
    firstTableColumns: "reportPeriod/sumFirstTableColumns",
    firstTableData: "reportPeriod/sumFirstTableData",
    isHiddenSecondTable: "reportPeriod/isHiddenSumSecondTable",
    secondTableColumns: "reportPeriod/sumSecondTableColumns",
    secondTableData: "reportPeriod/sumSecondTableData",
  }),
  methods: {
    ...mapMutations({
      toggleFirstTableVisibility:
        "reportPeriod/TOGGLE_SUM_FIRST_TABLE_VISIBILITY",
      toggleSecondTableVisibility:
        "reportPeriod/TOGGLE_SUM_SECOND_TABLE_VISIBILITY",
    }),
    ...mapActions({
      fetchFirstTableData: "reportPeriod/fetchSumFirstTableData",
      fetchSecondTableData: "reportPeriod/fetchSumSecondTableData",
    }),
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hideButtonHolder {
  margin-top: 20px;
}

.hideButton {
  width: 100%;
}
</style>
